<template>
  <div class='text-center py-32 px-8 max-w-lg mx-auto'>
    <exclamation-circle-icon class='w-full mb-4 text-red-600' size='6x' />
    <div class='font-semibold text-2xl w-full'>{{paymentFailTitle}}</div>
    <div class='mt-2 text-gray-500 mb-24 '>{{paymentFailDescription}}</div>
    <button
      @click='goToHome'
      class='border py-4 px-8 text-center text-white text-sm uppercase w-full bg-gray-900 block rounded-md'>
      {{ goToHomeButtonText }}
    </button>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from '@vue-hero-icons/outline'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'IamportRegistrationFailMoblie',
  components: {
    ExclamationCircleIcon
  },
  computed: {
    isKorean () {
      return this.$route.query.is_korean === 'true'
    },
    paymentFailTitle () {
      return this.isKorean ? '결제가 정상적으로 처리되지 않았습니다.' : 'Your payment could not be processed.'
    },
    paymentFailDescription () {
      return this.isKorean ? '결제를 다시 한번 진행해주세요.' : 'Please try again.'
    },
    goToHomeButtonText () {
      return this.isKorean ? '홈으로 가기' : 'Go Home'
    },

  },
  methods: {
    ...mapMutations('users', [
      'resetRegisteringAttendees',
    ]),
    ...mapActions('payments', [
      'resetCurrentOrder'
    ]),
    goToHome () {
      this.resetRegisteringAttendees()
      this.$router.push({name: 'Main'})
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.resetCurrentOrder()
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
